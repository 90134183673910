<template>
  <div class="main-content auth">
    <div class="auth-container box">
      <Switch :switchValue="!switchValue" @switchValueChanged="switchValue = !switchValue"/>
      <img class="auth__logo" src="@/assets/images/logos/LogoMeaning.svg">
      <div class="auth__titles">
        <h2>{{$t('invitesshow.register')}}</h2>
        <p class="subtitle">{{$t('invitesshow.enterdata')}}</p>
      </div> 
      <div class="auth__form">
        <!-- <div class="auth__social">
          <div class="auth__social-button auth__social-button--facebook">
            <img src="@/assets/images/logos/facebook.svg">
          </div>
          <div class="auth__social-button auth__social-button--google">
            <img src="@/assets/images/logos/google.svg">
          </div>
        </div> -->
        <div class="auth__own">
          <form @submit.prevent="acceptInvite()">
            <div class="form__group">
              <label class="form__label" for="email">{{$t('invitesshow.correo')}}</label>
              <input class="form__input" id="email" type="email" v-model.trim="user.email" required>
              <!-- <span class="form__input-info"></span> -->
            </div>
            <div class="grid grid-2">
              <div class="form__group">
                <label class="form__label" for="first-name">{{$t('invitesshow.nombre')}}</label>
                <input class="form__input" id="first-name" type="text" v-model.trim="user.first_name" required autofocus>
                <!-- <span class="form__input-info"></span> -->
              </div>
              <div class="form__group">
                <label class="form__label" for="last-name">{{$t('invitesshow.apellido')}}</label>
                <input class="form__input" id="last-name" type="text" v-model.trim="user.last_name" required>
                <!-- <span class="form__input-info"></span> -->
              </div>
            </div>
            <div class="form__group">
              <label class="form__label" for="password">{{$t('invitesshow.contra')}}</label>
              <input class="form__input" id="password" type="password" v-model="user.password" required>
              <!-- <span class="form__input-info"></span> -->
            </div>
            <div class="form__group">
              <label class="form__label" for="password-confirm">{{$t('invitesshow.confirmcontra')}}</label>
              <input class="form__input" id="password-confirm" type="password" v-model="passwordConfirm" required>
              <!-- <span class="form__input-info"></span> -->
            </div>
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit" :disabled="!(user.password && user.email && user.first_name && user.last_name) || user.password !== passwordConfirm">{{$t('invitesshow.registrar')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switch from '../components/Switch.vue'
export default {
  components: { Switch },
  name: 'InvitesShow',
  data () {
    return {
      user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        password: ''
      },
      passwordConfirm: ''
    }
  },
  methods: {
    async acceptInvite () {
      const res = await this.$axios.post(`auth/accept-invite/${this.$route.params.token}`, {
        id: this.user.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        password: this.user.password
      })
      if (res.data.token) {
        let authToken = `bearer ${res.data.token}`
        this.$axios.defaults.headers.common['Authorization'] = authToken
        this.$axios.get('init').then(() => {
          this.$cookies.set('jwt', authToken)
          this.$router.push('/')
        })
      } else {
        console.log(res)
      }
    }
  },
  async beforeMount () {
    const res = await this.$axios.get(`auth/accept-invite/${this.$route.params.token}`)
    if (res.data.invite) {
      this.user.id = res.data.invite.id
      this.user.email = res.data.invite.email
    } else {
      alert(this.$t('invitesshow.alertmsg'))
      this.$router.replace('/')
    }
  }
}
</script>
